import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
export const firebaseConfig = {
    apiKey: "AIzaSyCUmZtmvlqgc1l3E8janla7X9_-CdFDmPE",
    authDomain: "yologamestudios-com.firebaseapp.com",
    projectId: "yologamestudios-com",
    storageBucket: "yologamestudios-com.appspot.com",
    messagingSenderId: "1012053209184",
    appId: "1:1012053209184:web:3a4a5f430444bfe963d305",
    measurementId: "G-5726R1MY9W"
};

// Firebase'i başlat
const app = initializeApp(firebaseConfig);

// Auth nesnesini oluştur ve export et
export const auth = getAuth(app);